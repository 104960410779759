.modal-80w {
    max-width: 80% !important;
}

.images-row{
    margin-top: 10px;
    margin-bottom: 10px;
}

.files-row{
    margin-top: 10px;
    margin-bottom: 10px;
}
