:root {
  --background: rgb(255, 255, 240);
  --purple-value: 97, 29, 116;
  --purple: rgb(var(--purple-value));
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.NTHU-header {
  background-image: linear-gradient(0deg, var(--background) 0%, rgba(var(--purple-value), 0.6) 80%, rgba(var(--purple-value), 0.8) 100%);
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.title {
  color: var(--purple);
  text-align: left;
}

.logo-container {
  overflow: auto;
}

.logo {
  max-height: 50px;
  display: block;
  margin-left: auto;
}

.wrapper {
  min-height: calc(100% - 100px - 100px);
  background-color: var(--background);
}

.footer-space {
  height: 150px;
}

.footer {
  color: white;
  background-color: var(--purple);
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;
  bottom: 0;
  padding-top: 20px;
}

.footer p {
  overflow-x: auto;
}

p {
  white-space: pre-wrap;
}

@media only screen and (max-width: 768px) {
  .logo {
    margin: auto;
  }

  .title {
    text-align: center;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.cursor-pointer {
  cursor: pointer !important;
}