.reservation-table-cell-header {
    font-size: 10px !important;
}

.reservation-table-cell-p {
    font-size: 10px !important;
    color: white !important;
    white-space: pre-wrap;
}

.invalid-not-passed-cell {
    background-color: #e1c8e5 !important;
    border: 0;
}

.invalid-passed-cell {
    background-color: #d29fda !important;
    border: 0;
}

.semester-not-passed-cell {
    background-color: #e1c8e5 !important;
    border: 0;
}

.semester-passed-cell {
    background-color: #d29fda !important;
    border: 0;
}

.reservation-not-passed-cell {
    background-color: black !important;
    cursor: pointer !important;
}

.reservation-passed-cell {
    background-color: #794681 !important;
}

.empty-not-passed-cell {
    background-color: white !important;
    cursor: pointer !important;
}

.empty-passed-cell {
    background-color: #d29fda !important;
    border: 0;
}

.empty-not-passed-cell:hover {
    background-color: grey !important;
}

.reservation-not-passed-cell:hover {
    background-color: red !important;
    cursor: pointer !important;
}