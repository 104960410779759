.cell-header {
    font-size: 12px !important;
}

.cell-p {
    font-size: 17px !important;
    font-weight: bold;
    color: rgb(0, 0, 0) !important;
    justify-content: center;
}

.empty-cell {
    background-color: rgb(250, 250, 250) !important;
    cursor: pointer !important;
}

.reservation-cell {
    background-color: rgb(250, 250, 250) !important;
    cursor: pointer !important;
}

.reserved-cell {
    background-color: #ffffff !important;
    opacity: 0.6;
    border: 0;
    justify-content: center;
}

.reserved-cell:hover {
    background-color: grey !important;
    opacity: 0.6;
    border: 0;
}

.empty-cell:hover {
    background-color: grey !important;
}

.reservation-cell:hover {
    background-color: red !important;
    cursor: pointer !important;
}
