.user-invalid-cell {
    background-color: black !important;
    border: 0;
}

.user-valid-cell {
    background-color: white !important;
    cursor: pointer;
}

.user-valid-cell:hover {
    opacity: 0.6;
}

.user-reservation-cell {
    background-color: rgba(25, 135, 84, 1) !important;
    cursor: pointer;
}