.pageContainer {
    margin-top: 20px;
}

.type-title {
    width: 100%;
}

.btn {
    margin: 5px;
}


.banned{
    color: red;
}

.with-date{
    font-size: 10px;
    white-space: pre-wrap;
}

.type{
    font-size: 10px;
    white-space: pre-wrap;
}

.type-limits{
    font-size: 10px;
    white-space: pre-wrap;
}