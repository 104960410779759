.login-container{
    padding-top: 50px;
}

.forgot-password{
    display: flex;
    justify-content: flex-end;
    color: blue;
    margin: 10px;
}

.login-error-message-col{
    display: flex;
    align-items: center;
}

.login-error-message {
    margin: auto;
    color: red;
    size: 18px;
}