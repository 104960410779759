
.nav-color{
    color: purple;
}

.text-ticker{
    border: 2px solid black;
}

.navbar-container{
    padding: 0;
}